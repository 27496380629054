import { apiUrlMain, apiUrlProxy } from '@utils/constants';
import dynamic from 'next/dynamic';

export const routingManifest = {
  root: dynamic(() => import('@components/landing-pages/root-landing-page'), {
    ssr: false,
  }),
};

export const commonConfig = {
  apiUrlProxy: apiUrlProxy,
  apiUrlMain: apiUrlMain,
  logo: '/assets/images/archive-logo.svg',
  footerLogo: '/assets/images/archive-logo.svg',
  discordLogo: '/assets/images/discord1.svg',
  twitterLogo: '/assets/images/twitter1.svg',
};

export const customRoninConfig = {
  ...commonConfig,
  logo: '/assets/images/ronin-logo.svg',
  customStyle: {
    '--color-primary': '#2662d9',
    '--color-bg': '#20242D',
    '--color-text': 'white',
    '--color-dashboard-bg': '#111417',
    '--footer-text': 'white',
    '--font-primary':
      '-apple-system, BlinkMacSystemFont, Inter, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
    '--font-secondary':
      '-apple-system, BlinkMacSystemFont, Inter, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
    '--color-heading': 'white',
    '--breadcrumb-background': '#20242D',
    'font-feature-settings': '"tnum" on, "lnum" on, "ss01" on, "ss02" on, "ss04" on, "cv08" on, "case" on',
  },
};

export const protocolManifest = {
  balancer_v2: () => import('./protocols/balancer_v2'),
  uniswap_v3: () => import('./protocols/uniswap_v3'),
  aave_v3: () => import('./protocols/aave_v3'),
  aave_v2: () => import('./protocols/aave_v2'),
  matic_staking_v1_eth: () => import('./protocols/matic_staking_v1_eth'),
  sushiswap_v1_eth: () => import('./protocols/sushiswap_v1_eth'),
  lido_v1_eth: () => import('./protocols/lido_v1_eth'),
  sushiswap_farming_v1_eth: () => import('./protocols/sushiswap_farming_v1_eth'),
  ethereum_staking_v1_eth: () => import('./protocols/ethereum_staking_v1_eth'),
  compound_v3: () => import('./protocols/compound_v3'),
  pancakeswap_v2_bsc: () => import('./protocols/pancakeswap_v2_bsc'),
  pancakeswap_v3_bsc: () => import('./protocols/pancakeswap_v3_bsc'),
  gamma_finance_v1_polygon: () => import('./protocols/gamma_finance_v1_polygon'),
  verse_v1_eth: () => import('./protocols/verse_v1_eth'),
  trisolaris_v1_aurora: () => import('./protocols/trisolaris_v1_aurora'),
  across_v1_eth: () => import('./protocols/across_v1_eth'),
  evmos_v1_staking: () => import('./protocols/evmos_v1_staking'),
  cosmoshub_v1_staking: () => import('./protocols/cosmoshub_v1_staking'),
  spacefi_v1_evmos: () => import('./protocols/spacefi_v1_evmos'),
  ichi_v1: () => import('./protocols/ichi_v1'),
  clipper_v1: () => import('./protocols/clipper_v1'),
  bril_v1: () => import('./protocols/bril_v1'),
  retro_ichi_v1_polygon: () => import('./protocols/retro_ichi_v1_polygon'),
  orca_whirlpools_v1_solana: () => import('./protocols/orca_whirlpools_v1_solana'),
  avalanche_v1_staking: () => import('./protocols/avalanche_v1_staking'),
  maker_v1_eth: () => import('./protocols/maker_v1_eth'),
  rocketpool_v1_eth: () => import('./protocols/rocketpool_v1_eth'),
  katana_v1_ronin: () => import('./protocols/katana_v1_ronin'),
  rocketpool_income_v1_eth: () => import('./protocols/rocketpool_income_v1_eth'),
  quickswap_v3_polygon: () => import('./protocols/quickswap_v3_polygon'),
  morpho_v1: () => import('./protocols/morpho_v1'),
  aerodrome_veaero_v1_base: () => import('./protocols/aerodrome_veaero_v1_base'),
  aerodrome_v2_base: () => import('./protocols/aerodrome_v2_base'),
  aerodrome_v3_base: () => import('./protocols/aerodrome_v3_base'),
  velodrome_v2_optimism: () => import('./protocols/velodrome_v2_optimism'),
  velodrome_v3_optimism: () => import('./protocols/velodrome_v3_optimism'),
  spark_v1: () => import('./protocols/spark_v1'),
  fluid_v1: () => import('./protocols/fluid_v1'),
  uniswap_v2_eth: () => import('./protocols/uniswap_v2_eth'),
  moonwell_v1_base: () => import('./protocols/moonwell_v1_base'),
};
